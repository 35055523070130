import * as React from "react";
import { Seo } from "../components/seo";
import { Link } from "gatsby";
import { Layout } from "../layout";
import CareerImg from "../assets/img/bg-career.jpeg";

import CAREER_LOGO_1 from "../assets/svg/career-logo-1.svg";
import CAREER_LOGO_2 from "../assets/svg/career-logo-2.svg";
import CAREER_LOGO_3 from "../assets/svg/career-logo-3.svg";

const CareerPage = () => {
  return (
    <Layout>
      <Seo
        title="採用情報"
        description="FINEでは一緒に働ける仲間を募集しております。何年でも愛用される建物を手がけられるのは非常にやりがいのある仕事です。ぜひお問い合わせフォームよりご応募ください。"
      />
      <section className="mb-6">
        <div
          style={{ backgroundImage: `url(${CareerImg})` }}
          className="relative flex items-center justify-center 2xl:-mt-2 w-screen h-40vh bg-cover bg-center overflow-hidden md:h-60vh"
        >
          <h1 className="flex flex-col items-center justify-center tracking-widest">
            <span className="text-white text-2xl md:text-4xl">CAREER</span>
            <span className="mt-2 text-white text-xs md:text-base">
              採用情報
            </span>
          </h1>
          <div>
            <svg
              className="wave absolute bottom-0 inset-x-0 -mb-1 w-200vw text-white fill-current"
              viewBox="0 0 1440 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
            </svg>
          </div>
        </div>
      </section>
      <section className="mb-6 mx-auto px-6 md:px-10 lg:max-w-5xl lg:max-w-5xl">
        <div className="flex items-center justify-end">
          <div className="flex items-center justify-center">
            <span className="mr-2 text-primary-blue text-xs md:text-sm">
              <Link to="/">TOP</Link>
            </span>
            <span className="mr-2 text-xs md:text-sm">/</span>
            <span className="text-xs md:text-sm">採用情報</span>
          </div>
        </div>
      </section>
      {/* <!-- 採用情報 --> */}
      <section className="mb-12 mx-auto px-6 md:mb-24 md:px-10 lg:mb-32 lg:max-w-4xl">
        <div className="flex flex-col items-center justify-center mb-12 lg:mb-20">
          <div className="mb-2 md:mb-4">
            <h2 className="text-primary-blue md:text-xl">求める人材</h2>
          </div>
          <div className="w-8 h-0.5 bg-primary-blue md:w-12"></div>
        </div>
        <div className="flex flex-col mb-12 lg:mb-20">
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_1} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                求める能力
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                ■高卒以上　20歳～
                <br />
                ■普通自動車運転免許をお持ちの方（AT限定不可）
                <br />
                ■未経験者・初心者OK
                <br />
                ※内装業における実務経験をお持ちの方大歓迎！
                <br />
              </p>
            </div>
          </div>
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-3 w-7 md:w-9">
                <img src={CAREER_LOGO_2} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                こんな方からの応募、尚歓迎！
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                ◎CADソフト（Vectorworks等）の操作スキルをお持ちの⽅
                <br />
                ◎営業経験をお持ちの⽅
                <br />
                ◎接客や販売の仕事に携わっていた⽅
                <br />
                ◎施⼯管理の経験をお持ちの⽅
                <br />
              </p>
            </div>
          </div>
          <div className="relative mt-4 mx-auto w-fit-content">
            <Link
              className="
                  btn-background-slide
                  group
                  flex
                  items-center
                  justify-center
                  mx-auto
                  py-2
                  w-48
                  bg-primary-blue
                  md:py-4
                  md:w-64
                "
              to="/contact"
            >
              <span
                className="
                    z-10
                    group-hover:text-primary-blue
                    text-white text-sm
                    tracking-wider
                    transition
                    duration-300
                    ease-in-out
                    md:text-base
                  "
              >
                応募はこちら
              </span>
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center mb-12 lg:mb-20">
          <div className="mb-2 md:mb-4">
            <h2 className="text-primary-blue md:text-xl">募集要項</h2>
          </div>
          <div className="w-8 h-0.5 bg-primary-blue md:w-12"></div>
        </div>
        <div className="flex flex-col mb-12 lg:mb-20">
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_3} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                募集職種
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                施工監理（店舗内装）、家具職人
              </p>
            </div>
          </div>
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_3} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                仕事内容
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                <span
                  className="
                      mb-2
                      text-primary-blue text-xs
                      font-semibold
                      tracking-wider
                      md:text-sm
                      lg:text-base
                    "
                >
                  誰もが知るようなショップを手がけていただきます。
                </span>
                若者を中心に人気の高いアパレルショップや量販店のコスメコーナーといった商業施設の施工監理をお任せします。完成した店舗が雑誌やテレビで取り上げられることもあり、成果を実感できる仕事です。
                <br />
                <br />
                実際に現場に出て“職人の技”を見ながら仕事の流れを覚えていきましょう。わからないことがあれば、社長をはじめとしたメンバーに何でも聞いてください。意見を発信することで、最適なアドバイスをすることができます。自分次第で成長できる環境です。
              </p>
            </div>
          </div>
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_3} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                基本的な仕事の流れ
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                ▼ 工事現場の設計・施主との打合せ
                <br />
                ▼ 見積り作成
                <br />
                ▼ 実測・調査
                <br />
                ▼ 発注
                <br />
                ▼ 各下請け業者と段取り
                <br />
                ▼ 現場における施工監理
                <br />
                ▼ 工事完了後の精算
                <br />
              </p>
            </div>
          </div>
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_3} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                仕事の醍醐味
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                実際に多くの人物がショップに立ち寄る様子を見るなど、仕事の成果を実感することができます。自分の携わった店舗が雑誌やテレビに取り上げられたときには、大きな達成感を得られるでしょう。何年でも愛用される建物を手がける味わい深い仕事です。
              </p>
            </div>
          </div>
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_3} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                仕事の厳しさ
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                納期が短かったとしても、イレギュラーが発生したとしても、決して妥協は許されません。ブランドショップを中心とするクライアントの期待に応えるため、高いクオリティが求められます。うまく段取りを組んで職人に指示を出し、工期通りに進むよう調整していくことが不可欠です。
              </p>
            </div>
          </div>
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_3} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                雇用形態
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                正社員
                <br />
                ※3ヶ月の試用期間あり（期間中の給与・待遇は変わりません）。
                <br />
              </p>
            </div>
          </div>
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_3} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                勤務地
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                大阪／大阪府八尾市北木の本　【監理、家具職人】
                <br />
                東京／東京都世田谷区　【監理】
                <br />
                ※ご希望を考慮して決定します。
                <br />
              </p>
            </div>
          </div>
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_3} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                勤務時間
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                大阪／9：00～18：00 <br />
                東京／9：00～18：00 <br />
              </p>
            </div>
          </div>
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_3} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                給与
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                月給　20万円～
              </p>
            </div>
          </div>
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_3} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                待遇・福利厚生
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                ■賞与年2回（7月・12月）、決算賞与（業績に応じて支給）
                <br />
                ■交通費全額支給、各種社会保険完備
                <br />
              </p>
            </div>
          </div>
          <div className="mb-6 md:mb-8 lg:mb-12">
            <div
              className="
                  flex
                  items-center
                  mb-2
                  pb-2
                  w-full
                  border-b-2 border-primary-blue
                  md:pb-3
                  lg:pb-4
                "
            >
              <span className="mr-4 w-6 md:w-8">
                <img src={CAREER_LOGO_3} alt="" />
              </span>
              <span
                className="
                    text-primary-blue text-sm
                    tracking-wider
                    md:text-base
                    lg:text-lg
                  "
              >
                休日休暇
              </span>
            </div>
            <div>
              <p
                className="
                    pt-1
                    text-xs
                    tracking-wider
                    leading-5
                    md:pt-2
                    md:text-sm
                    md:leading-6
                    lg:pt-3
                    lg:text-base
                    lg:leading-7
                  "
              >
                ■週休2日制（日、第二・第四土曜日）、祝日
                <br />
                ■年末年始休暇（8～10日）、GW <br />
              </p>
            </div>
          </div>
          <div className="relative mt-4 mx-auto w-fit-content">
            <Link
              className="
                  btn-background-slide
                  group
                  flex
                  items-center
                  justify-center
                  mx-auto
                  py-2
                  w-48
                  bg-primary-blue
                  md:py-4
                  md:w-64
                "
              to="/contact"
            >
              <span
                className="
                    z-10
                    group-hover:text-primary-blue
                    text-white text-sm
                    tracking-wider
                    transition
                    duration-300
                    ease-in-out
                    md:text-base
                  "
              >
                応募はこちら
              </span>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CareerPage;
